import { Injectable } from '@angular/core';
import { restEndPoint } from '../core-constants.service';
import { CacheableHttpClient } from '../services/cache/cacheable-http-client';
import { Observable } from 'rxjs';
import { BusinessEvent } from '../models/business-event';
import { HttpParams } from '@angular/common/http';

export const getBusinessEventRestEndpoint = restEndPoint + 'businessEvents/get';
export const addBusinessEventRestEndpoint = restEndPoint + 'businessEvents/add';

export const addAndRemoveMarketingConsentBusinessEventRestEndpoint = restEndPoint + 'businessEvents/add-marketing-consent-event';
export const checkMarketingConsentBusinessEventRestEndpoint = restEndPoint + 'businessEvents/marketing-consent';

@Injectable({providedIn: 'root'})
export class BusinessEventsRestService {

  constructor(private http: CacheableHttpClient) {
  }

  getBusinessEvent(name: string): Observable<BusinessEvent> {
    const httpParams = new HttpParams()
      .set('name', name);
    return this.http.get<BusinessEvent>(getBusinessEventRestEndpoint, {
      params: httpParams,
      cache: false
    });
  }

  addBusinessEvent(name: string, info: string): Observable<BusinessEvent> {
    const httpParams = new HttpParams()
      .set('name', name)
      .set('info', info);
    return this.http.post<BusinessEvent>(addBusinessEventRestEndpoint, {params: httpParams});
  }

  addNewAndRemoveOldMarketingConsentEvent(): Observable<BusinessEvent> {
    return this.http.post<BusinessEvent>(addAndRemoveMarketingConsentBusinessEventRestEndpoint, {});
  }
  getMarketingConsent(): Observable<boolean> {
    return this.http.get<boolean>(checkMarketingConsentBusinessEventRestEndpoint, {});
  }
}
